import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon } from '@pancakeswap/uikit'
import { Link } from 'react-router-dom'
import Settings from './Settings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  return (
    <div id="modalcasual" style={{background: '#673ab7', borderRadius: '3px'}}>
      <AppHeaderContainer>
        <Flex alignItems="center" mr={noConfig ? 0 : '16px'}>
          {backTo && (
            <IconButton as={Link} to={backTo}>
              <ArrowBackIcon width="32px" style={{color: '#5dadec'}}/>
            </IconButton>
          )}
          <Flex flexDirection="column">
            <Heading as="h2" mb="8px" style={{color: 'white'}}>
              {title}
            </Heading>
            <Flex alignItems="center">
              {helper && <QuestionHelper text={helper} mr="4px" />}
              <Text color="textSubtle" fontSize="14px" style={{color: 'white'}}>
                {subtitle}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {!noConfig && (
          <Flex>
            <Settings />
            <Transactions />
          </Flex>
        )}
      </AppHeaderContainer>
    </div>
  )
}

export default AppHeader
